import React, { useState, useEffect } from "react";

const Pagination = (props) => {
	const [pageNumbers, setPageNumbers] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [lastPage, setLastPage] = useState(0);
	useEffect(() => {
		const { pages } = props;
		setPageNumbers([...Array(pages).keys()]);
		setLastPage(pages - 1);
	}, [props]);
	const changePage = (val) => {
		console.log(typeof val);
		if (val == "prev" && currentPage !== 0) {
			const newPageVal = currentPage - 1;
			props.handlePageChange(newPageVal);
			setCurrentPage(newPageVal);
		} else if (val == "next" && currentPage < pageNumbers.length - 1) {
			const newPageVal = currentPage + 1;
			setCurrentPage(newPageVal);
		} else if (typeof val == "number") {
			props.handlePageChange(val);
			setCurrentPage(val);
		}
	};
	const pageItems = () => {
		return pageNumbers.map((value) => (
			<>
				{value > currentPage - 4 && value < currentPage + 4 && (
					<li
						className={`paginate_button page-item ${
							currentPage == value ? "active" : ""
						}`}
						key={value}
						onClick={(e) => changePage(value)}
					>
						<a
							href="javascript:void(0)"
							aria-controls="kt_datatable_2"
							data-dt-idx={value + 1}
							tabindex="0"
							className="page-link"
						>
							{value + 1}
						</a>
					</li>
				)}
			</>
		));
	};
	return (
		<div className="dataTables_wrapper dt-bootstrap4 no-footer">
			<div className="col-sm-12 dataTables_pager d-flex justify-content-center">
				<div
					className="dataTables_paginate paging_simple_numbers"
					id="kt_datatable_2_paginate"
				>
					<ul className="pagination">
						<li
							className="paginate_button page-item previous"
							id="kt_datatable_2_previous"
							onClick={(e) => changePage(0)}
						>
							<a
								href="javascript:void(0)"
								aria-controls="kt_datatable_2"
								data-dt-idx="0"
								tabindex="0"
								className="page-link"
							>
								<i className="ki ki-double-arrow-back"></i>
							</a>
						</li>
						<li
							className="paginate_button page-item previous"
							id="kt_datatable_2_previous"
							onClick={(e) => changePage("prev")}
						>
							<a
								href="javascript:void(0)"
								aria-controls="kt_datatable_2"
								data-dt-idx="0"
								tabindex="0"
								className="page-link"
							>
								<i className="ki ki-arrow-back"></i>
							</a>
						</li>
						{pageItems()}
						<li
							className="paginate_button page-item next"
							id="kt_datatable_2_next"
							onClick={(e) => changePage("next")}
						>
							<a
								href="javascript:void(0)"
								aria-controls="kt_datatable_2"
								data-dt-idx="6"
								tabindex="0"
								className="page-link"
							>
								<i className="ki ki-arrow-next"></i>
							</a>
						</li>
						<li
							className="paginate_button page-item next"
							id="kt_datatable_2_next"
							onClick={(e) => changePage(lastPage)}
						>
							<a
								href="javascript:void(0)"
								aria-controls="kt_datatable_2"
								data-dt-idx="6"
								tabindex="0"
								className="page-link"
							>
								<i className="ki ki-double-arrow-next"></i>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
