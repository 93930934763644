import React, { Component } from "react";
import Axios from "../../redux/axiosHandlerGeo";
import { Card } from "react-bootstrap";
import PersonCard from "../components/PersonCard";

class Representative extends Component {
	constructor(props) {
		super(props);
		this.state = {
			people: [],
		};
	}

	componentDidMount() {
		Axios.get("/api/staff/mainPage")
			.then((res) => {
				this.setState({
					people: res.data,
				});
			})
			.catch((err) => {
				console.log("dataSurvey err", err);
			});
	}
	render() {
		return (
			<div className="row h-100">
				{this.state.people &&
					this.state.people.map((person, index) => {
						return <PersonCard data={person} key={index} />;
					})}
			</div>
		);
	}
}

export default Representative;
