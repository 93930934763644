import React from "react";
import { withRouter } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import { CircularProgress } from "@material-ui/core";
import { LayoutSplashScreen } from "../../layout";
import { connect } from "react-redux";

// TODO: Should be rewrited to pure function
class AnimateLoading extends React.Component {
  animateTimeout;
  stopAnimateTimeout;
  state = {
    width: 0,
    routeChanged: false
  };

  componentDidUpdate(nextProps) {
    console.log(nextProps)
    if (this.props.location.pathname !== nextProps.location.pathname || this.props.selectedLanguage !== nextProps.selectedLanguage || this.props.wardDetails.ward !== nextProps.wardDetails.ward) {
      this.animate();
      this.scrollToTop();
      let bodies = document.getElementById('root')
      if (bodies) {
        bodies.classList.add('stop-scrolling')
      }
    }
  }
  componentDidMount() {
    this.animate();
    this.scrollToTop();
    let bodies = document.getElementById('root')
    if (bodies) {
      bodies.classList.add('stop-scrolling')
    }
  }

  scrollToTop() {
    const scrollToTopBtn = document.getElementById("kt_scrolltop");
    if (scrollToTopBtn) {
      scrollToTopBtn.click();
    }
  }

  animate() {
    this.animateTimeout = setTimeout(() => {
      if (this.state.width <= 100) {
        this.setState({ width: this.state.width + 10 });
        this.animate();
      } else {
        this.stopAnimate();
      }
    }, 30);
  }
  stopAnimate() {
    clearTimeout(this.animateInterval);
    this.stopAnimateTimeout = setTimeout(() => {
      this.setState({ width: 0 });
      let bodies = document.getElementById('root')
      if (bodies) {
        bodies.classList.remove('stop-scrolling')
      }
    }, 300);
  }
  componentWillUnmount() {
    if (this.stopAnimateTimeout) {
      clearTimeout(this.stopAnimateTimeout);
    }
    if (this.animateTimeout) {
      clearTimeout(this.animateTimeout);
    }
  }
  render() {
    return (

      <>
        {
          this.state.width > 0 && (
            <LayoutSplashScreen />
            // <div className="splash-screen">
            //   <img
            //     src={toAbsoluteUrl("/media/logos/logo-mini-md.png")}
            //     alt="Metronic logo"
            //   />
            //   <CircularProgress className="splash-screen-spinner" />
            // </div>
          )
        }
      </>
      // <div
      //   className="header-progress-bar"
      //   style={{ height: "3px", width: "100%" }}
      // >
      //   {this.state.width > 0 && (
      //     <ProgressBar variant="info" now={this.state.width} style={{ height: "3px" }}  />
      //   )}
      // </div>
    );
  }
}


const mapStateToProps = (state) => ({
  selectedLanguage: state.ward.selectedLanguage,
  wardDetails: state.ward.wardDetails,
});
export default connect(mapStateToProps)(withRouter(AnimateLoading));
