import React, { Component } from "react";
import BarGraphCard from "../components/BarGraphCard";
import PieChartProgressCard from "../components/PieChartProgressCard";
import { connect } from "react-redux";
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";

class EconomicDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			surveyData: [],
			barData: [],
		};
	}
	componentDidMount() {
		this.props.dispatch(actions.fetchBankAccountYesNo());
		this.props.dispatch(actions.fetchIsLoan());
		this.props.dispatch(actions.fetchAnnualIncome());
		this.props.dispatch(actions.fetchAbroad());
		this.props.dispatch(actions.fetchAnnualExpense());
		this.props.dispatch(actions.fetchFoodForMonth());
		this.props.dispatch(actions.fetchSkill());
		this.props.dispatch(actions.fetchAnnualAgriExpense());
		this.props.dispatch(actions.fetchAnnualAgriIncome());
	}
	componentWillReceiveProps(nextProps) {
		// You don't have to do this check first, but it can help prevent an unneeded render
		if (nextProps !== this.props) {
			let surveyData = [
				{
					id: 1,
					data: nextProps.detail.selectedBankAccountYesNo?.data.map(
						({ value }) => value
					),
					containerClass: "col-md-6",
					labels: nextProps.detail.selectedBankAccountYesNo?.data.map(
						({ value }) => value
					),
					labelDesc: nextProps.detail.selectedBankAccountYesNo?.data.map(
						(datum) => {
							return datum[nextProps.detail.selectedLanguage];
						}
					),
					title:
						nextProps.detail.bankAccountYesNoList.title[
							nextProps.detail.selectedLanguage
						],
					color: "success",
					value: nextProps.detail.bankAccountYesNoList.percentage,
				},
				{
					id: 2,
					data: nextProps.detail.selectedIsLoan?.data.map(({ value }) => value),
					containerClass: "col-md-6",
					labels: nextProps.detail.selectedIsLoan?.data.map(
						({ value }) => value
					),
					labelDesc: nextProps.detail.selectedIsLoan?.data.map((datum) => {
						return datum[nextProps.detail.selectedLanguage];
					}),
					title:
						nextProps.detail.isLoanList.title[
							nextProps.detail.selectedLanguage
						],
					color: "success",
					value: nextProps.detail.isLoanList.percentage,
				},
			];
			this.setState({ surveyData });

			if (
				nextProps.detail.selectedAnnualExpense !==
					this.props.detail.selectedAnnualExpense ||
				nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
			) {
				let totalData = nextProps.detail.selectedAnnualExpense?.data.map(
					({ value }) => value
				);
				let labelData = nextProps.detail.selectedAnnualExpense?.data.map(
					(datum) => {
						return datum[nextProps.detail.selectedLanguage];
					}
				);
				let annualExpenseData = {
					id: 3,
					values: [
						{
							name: "",
							data: totalData,
						},
					],
					title:
						nextProps.detail.annualExpenseList.title[
							nextProps.detail.selectedLanguage
						],
					categories: labelData,
					detail: "AnnualExpense",
					color: "#125D98",
					xaxis: "Average Annual Expenditure (Rs)",
					xaxis_nepali: "खर्च",
				};
				this.setState((previousState) => {
					let barData = previousState.barData;
					let prevWardData = previousState.barData.find((i) => i.id === 3);
					if (!prevWardData) {
						barData.push(annualExpenseData);
					} else {
						let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
						barData[prevWardDataIndex] = annualExpenseData;
					}
					return {
						barData,
					};
				});
			}
			if (
				nextProps.detail.selectedAnnualIncome !==
					this.props.detail.selectedAnnualIncome ||
				nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
			) {
				let totalData = nextProps.detail.selectedAnnualIncome?.data.map(
					({ value }) => value
				);
				let labelData = nextProps.detail.selectedAnnualIncome?.data.map(
					(datum) => {
						return datum[nextProps.detail.selectedLanguage];
					}
				);
				let annualIncomeData = {
					id: 4,
					values: [
						{
							name: "",
							data: totalData,
						},
					],
					title:
						nextProps.detail.annualIncomeList.title[
							nextProps.detail.selectedLanguage
						],
					categories: labelData,
					detail: "AnnualIncome",
					color: "#346751",
					xaxis: "Average Annual Income (Rs)",
					xaxis_nepali: "आम्दानी",
				};
				this.setState((previousState) => {
					let barData = previousState.barData;
					let prevWardData = previousState.barData.find((i) => i.id === 4);
					if (!prevWardData) {
						barData.push(annualIncomeData);
					} else {
						let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
						barData[prevWardDataIndex] = annualIncomeData;
					}
					return {
						barData,
					};
				});
			}
			if (
				nextProps.detail.selectedAbroad !== this.props.detail.selectedAbroad ||
				nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
			) {
				let femaleData = nextProps.detail.selectedAbroad?.data.female?.map(
					({ value }) => value
				);
				let maleData = nextProps.detail.selectedAbroad?.data.male?.map(
					({ value }) => value
				);
				let thirdData = nextProps.detail.selectedAbroad?.data.third?.map(
					({ value }) => value
				);
				let labelData = nextProps.detail.selectedAbroad?.data.third?.map(
					(datum) => {
						return datum[nextProps.detail.selectedLanguage];
					}
				);
				let femaleTitle =
					nextProps.detail.selectedLanguage === "nepali" ? "महिला" : "Female";
				let maleTitle =
					nextProps.detail.selectedLanguage === "nepali" ? "पुरुष" : "Male";
				let thirdTitle =
					nextProps.detail.selectedLanguage === "nepali" ? "अन्य" : "Other";
				// console.log(labelData)
				let abroadData = {
					id: 2,
					values: [
						{
							name: femaleTitle,
							data: femaleData,
						},
						{
							name: maleTitle,
							data: maleData,
						},
						{
							name: thirdTitle,
							data: thirdData,
						},
					],
					title:
						nextProps.detail.abroadList.title[
							nextProps.detail.selectedLanguage
						],
					categories: labelData,
					detail: "Abroad",
					xaxis: "Country",
					xaxis_nepali: "देश",
				};
				this.setState((previousState) => {
					let barData = previousState.barData;
					let prevWardData = previousState.barData.find((i) => i.id === 2);
					if (!prevWardData) {
						barData.push(abroadData);
					} else {
						let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
						barData[prevWardDataIndex] = abroadData;
					}
					return {
						barData,
					};
				});
			}
			if (
				nextProps.detail.selectedFoodForMonth !==
					this.props.detail.selectedFoodForMonth ||
				nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
			) {
				let data = nextProps.detail.selectedFoodForMonth?.data.map(
					({ value }) => value
				);
				let labelData = nextProps.detail.selectedFoodForMonth?.data.map(
					(datum) => {
						return datum[nextProps.detail.selectedLanguage];
					}
				);
				// console.log(labelData)
				let foodForMonthData = {
					id: 5,
					values: [
						{
							name: "",
							data: data,
						},
					],
					title:
						nextProps.detail.foodForMonthList.title[
							nextProps.detail.selectedLanguage
						],
					categories: labelData,
					detail: "FoodForMonth",
					xaxis: "Time Period",
					xaxis_nepali: "अवधि",
					color: "#66DE93",
				};
				this.setState((previousState) => {
					let barData = previousState.barData;
					let prevWardData = previousState.barData.find((i) => i.id === 5);
					if (!prevWardData) {
						barData.push(foodForMonthData);
					} else {
						let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
						barData[prevWardDataIndex] = foodForMonthData;
					}
					return {
						barData,
					};
				});
			}
			if (
				nextProps.detail.selectedAnnualAgriIncome !==
					this.props.detail.selectedAnnualAgriIncome ||
				nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
			) {
				let data = nextProps.detail.selectedAnnualAgriIncome?.data.map(
					({ value }) => value
				);
				let labelData = nextProps.detail.selectedAnnualAgriIncome?.data.map(
					(datum) => {
						return datum[nextProps.detail.selectedLanguage];
					}
				);
				// console.log(labelData)
				let annualAgriIncomeData = {
					id: 6,
					values: [
						{
							name: "",
							data: data,
						},
					],
					title:
						nextProps.detail.annualAgriIncomeList.title[
							nextProps.detail.selectedLanguage
						],
					categories: labelData,
					detail: "AnnualAgriIncome",
					xaxis: "Average Annual Incocme (Agriculture)",
					xaxis_nepali: "",
					color: "#B4846C",
				};
				this.setState((previousState) => {
					let barData = previousState.barData;
					let prevWardData = previousState.barData.find((i) => i.id === 6);
					if (!prevWardData) {
						barData.push(annualAgriIncomeData);
					} else {
						let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
						barData[prevWardDataIndex] = annualAgriIncomeData;
					}
					return {
						barData,
					};
				});
			}
			if (
				nextProps.detail.selectedAnnualAgriExpense !==
					this.props.detail.selectedAnnualAgriExpense ||
				nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
			) {
				let data = nextProps.detail.selectedAnnualAgriExpense?.data.map(
					({ value }) => value
				);
				let labelData = nextProps.detail.selectedAnnualAgriExpense?.data.map(
					(datum) => {
						return datum[nextProps.detail.selectedLanguage];
					}
				);
				// console.log(labelData)
				let annualAgriExpenseData = {
					id: 7,
					values: [
						{
							name: "",
							data: data,
						},
					],
					title:
						nextProps.detail.annualAgriExpenseList.title[
							nextProps.detail.selectedLanguage
						],
					categories: labelData,
					detail: "AnnualAgriExpense",
					xaxis: "Average Annual Expenditure (Agriculture)",
					xaxis_nepali: "",
					color: "#94D0CC",
				};
				this.setState((previousState) => {
					let barData = previousState.barData;
					let prevWardData = previousState.barData.find((i) => i.id === 7);
					if (!prevWardData) {
						barData.push(annualAgriExpenseData);
					} else {
						let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
						barData[prevWardDataIndex] = annualAgriExpenseData;
					}
					return {
						barData,
					};
				});
			}
			if (
				nextProps.detail.selectedSkill !== this.props.detail.selectedSkill ||
				nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
			) {
				let femaleData = nextProps.detail.selectedSkill?.data.female?.map(
					({ value }) => value
				);
				let maleData = nextProps.detail.selectedSkill?.data.male?.map(
					({ value }) => value
				);
				let thirdData = nextProps.detail.selectedSkill?.data.third?.map(
					({ value }) => value
				);
				let labelData = nextProps.detail.selectedSkill?.data.third?.map(
					(datum) => {
						return datum[nextProps.detail.selectedLanguage];
					}
				);
				let femaleTitle =
					nextProps.detail.selectedLanguage === "nepali" ? "महिला" : "Female";
				let maleTitle =
					nextProps.detail.selectedLanguage === "nepali" ? "पुरुष" : "Male";
				let thirdTitle =
					nextProps.detail.selectedLanguage === "nepali" ? "अन्य" : "Other";
				// console.log(labelData)
				let skillData = {
					id: 2,
					values: [
						{
							name: femaleTitle,
							data: femaleData,
						},
						{
							name: maleTitle,
							data: maleData,
						},
						{
							name: thirdTitle,
							data: thirdData,
						},
					],
					title:
						nextProps.detail.skillList.title[nextProps.detail.selectedLanguage],
					categories: labelData,
					detail: "Skill",
					xaxis: "Skills",
					xaxis_nepali: "शिप",
				};
				this.setState((previousState) => {
					let barData = previousState.barData;
					let prevWardData = previousState.barData.find((i) => i.id === 2);
					if (!prevWardData) {
						barData.push(skillData);
					} else {
						let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
						barData[prevWardDataIndex] = skillData;
					}
					return {
						barData,
					};
				});
			}
		}
	}
	render() {
		return (
			<>
				<div className="row" id="details">
					{this.state.surveyData.map((data, index) => {
						return (
							<PieChartProgressCard
								data={data}
								key={index}
								section="economic_pie_"
							/>
						);
					})}
					{this.state.barData.map((datum, index) => {
						return (
							<BarGraphCard
								datum={datum}
								key={index}
								section="economic_bar_graph"
							/>
						);
					})}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	detail: state.ward,
});
export default connect(mapStateToProps)(EconomicDetail);
